<template>
  <div>
    <transition name="router-anim" :enter-active-class="`animated fadeInUp`" mode="out-in"
                :leave-active-class="`animated fadeOut`">
    <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
}
</script>
